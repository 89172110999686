<template>
  <div>
    <!-- Header -->
    <div class="header bg-light py-7 py-lg-8 pt-lg-2">
      <b-container class="container">
        <div class="header-body text-center mb-4">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <Logo></Logo>
            </b-col>
          </b-row>
        </div>
      </b-container>

    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <!-- Table -->
      <b-row class="justify-content-center">
        <b-col lg="6" md="8" >
          <b-card no-body class="bg-secondary border-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Inscrivez vous</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-hat-3"
                              placeholder="Nom d'utilisateur"
                              name="Name"
                              :rules="{required: true}"
                              v-model="user.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-lock-circle-open"
                              placeholder="password"
                              type="password"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              v-model="user.password">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="fas fa-signature"
                              placeholder="Prénom"
                              name="firstName"
                              :rules="{required: true}"
                              v-model="user.firstName">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="fas fa-signature"
                              placeholder="Nom"
                              name="lastName"
                              :rules="{required: true}"
                              v-model="user.lastName">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              name="Email"
                              :rules="{required: true, email: true}"
                              v-model="user.email">
                  </base-input>


                  <!--<div class="text-muted font-italic"><small>password strength: <span
                    class="text-success font-weight-700">strong</span></small></div>-->
                  <div class="text-center">
                    <b-button type="submit" variant="primary" class="mt-4">Create account</b-button>
                  </div>
                </b-form>
              </validation-observer>
              <div>
                <p>{{message}}</p>
              </div>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/login" class=""><small>Retour a la page de connexion</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

  import AuthService from "../../services/AuthService";
  import Logo from "../../components/Logo";

  export default {
    name: 'register',
    data() {
      return {
        user: {
          username: "",
          password: "",
          email: "",
          firstName: "",
          lastName: ""
        },
        message:''
      }
    },
    components:{
      Logo
    },
    methods: {
      onSubmit() {
        AuthService.register(this.user)
        .then((response) => {
          if(response === 201){
            this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: 'Le compte a été créé. Vous pourrez accceder à l\'application une fois que celui-ci aura été activé.', type: 'success'})
            this.user.lastName=''
            this.user.firstName=''
            this.user.username=''
            this.user.email=''
            this.user.password=''
            this.message='Votre compte a été créé. il sera activé prochainement.'
          }else{
            this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: 'Erreur lors de la création du compte.', type: 'warning'})
            this.message='Erreur lors de la création du compte'
          }
        })
        .catch((error)=>{
          this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: 'Erreur lors de la création du compte.', type: 'warning'})
          this.message='Erreur lors de la création du compte'
        })
      }
    }

  };
</script>
<style></style>
